<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

 <template>
	<div class="sign-in">
		
		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 80px;">

			<a-col :span="24" :md="18" :lg="18" :xl="{span: 8, offset: 0}" class="col-form pt-20 mt-20">
				
				<a-card :bordered="false" class="bg-white px-0 mx-0 pt-10">
					<a-row :gutter="[24,24]">
						
						<a-col :span="24" :md="24" :lg="24">
							<a-row class="text-center mb-0 pb-0">
								<a-col :lg="24" class="mb-0 pb-0">
									<img src="images/logo.png" alt="" class="mb-0 pb-0" width="150px" height="150px" style="border-radius: 20px;"> 
								</a-col>
							</a-row>
							<h3 class="mb-0 mt-0 pt-0 text-center">Welcome Back!</h3>
							<p class="text-muted text-center mt-0 pt-0">Login to continue</p>
 
							<a-alert
                                class="mx-25"
								v-if="error.show"
								:message="error.message"
								type="error"
								closable
								:after-close="handleClose"
								show-icon 
							/>

							<a-form
								id="components-form-demo-normal-login"
								:form="form"
								class="login-form mx-25 mt-20"
								@submit="handleSubmit"
								:hideRequiredMark="true">

                                <a-form-item class="mb-5" label="Username" :colon="false">
                                    <a-input 
                                        v-decorator="[
                                        'username',
                                        { rules: [{ required: true, message: 'Please input your username!' }] },
                                        ]" placeholder="Email address" />
                                </a-form-item>
								
								<a-form-item class="mb-5" label="Password" :colon="false">
									<a-input
									v-decorator="[
									'password',
									{ rules: [{ required: true, message: 'Please input your password!' }] },
									]" type="password" placeholder="Password" />
								</a-form-item>

								<a-form-item class="mt-20">
									<a-button :loading="isLoading" type="primary" block html-type="submit" class="login-form-button mt-10 text-white" >
										SIGN IN
									</a-button>
								</a-form-item>

								
							</a-form>
						</a-col>
					</a-row>
				</a-card>
			</a-col>


		</a-row>
		
	</div>
</template>

<script>
	export default ({
		

		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				error: {
					message: null,
					show: false,
				},

				countryCode: '255',

				isLoading: false,
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		created() {
		},
		methods: {

			onSelectCountry({name, iso2, dialCode}) {
				this.countryCode = dialCode;
			},

			handleClose() {
				this.error.show = false;
			},

			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
						this.error.show = false;
						this.error.message = null;
						this.isLoading = true;

						const constUsername = values.username;
						let username = '';
						const password = values.password;

						console.log(constUsername.substring(0, 4));

						if(!constUsername.includes("@")){

							if(constUsername.substring(0, 4).includes(this.countryCode) && !constUsername.includes("+")) {
								username = "+" + constUsername;
							}

							else if(constUsername.substring(0, 4).includes(this.countryCode) && constUsername.includes("+")) {
								username =  constUsername;
							}

							else if(constUsername[0] == "0" || constUsername[0] == 0) {
								username = "+" + this.countryCode + "" + constUsername.substring(1)
							}

							else if(constUsername[0] != "0" || constUsername[0] != 0) {
								username = "+" + this.countryCode + "" + constUsername
							}

							else {
								username = constUsername;
							}

						}else{
							username = constUsername;
						}

						let url = `${this.$BACKEND_URL}/auth/login`;
						
						this.$AXIOS.post(url, { username, password }).then(async(response) => {
							if (response.status >= 200 && response.status < 210) {
								await localStorage.setItem("resinToken", response.data.token)
								await localStorage.setItem("resinDetails", JSON.stringify(response.data.user))
								
								this.isLoading = false;


								this.$router.push({ path: '/dashboard' }).then(() => { this.$router.go() })

								// this.$router.push(`/dashboard`); 

								
							}
							this.isLoading = false
						}).catch((err) => {
							console.log(err)
							this.isLoading = false;
							
							this.error.message = err.response != null  && err.response.data != null ? err.response.data.message : "Connection error";
							this.error.show = true
						});
					}
				});
			},
		},
	})

</script>

<style lang="scss" scoped>
	body {
		background-color: #ffffff;
	}
	// #signinBtn, #signinBtn:hover {
	// 	background-color: #632163 !important;
	// 	color: white !important;
	// }
</style>
